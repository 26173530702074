export default {
  state: {
    mode: null,
    selectedModelForRender: null,
    renderStep: 1,
    renderName: null,
    render: null,
    renderSearch: null,
    materialRender: '',
    loadRenderData: false,
    isRenderWidgetLoaded: false,
    renderTab: 0,
    renderChangeScaleWhilePreview: false,
    renderChangeHDRIWhilePreview: false,
  },
  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },
  actions: {
    SET_VALUE ({ commit }, keyValue) {
      commit('setValue', keyValue)
    }
  }
}