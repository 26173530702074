export default {
  state: {
    typeEdit: 'list',
    hdriEdit: null,
    hdris: null,
    initialItem: 0,
    getHdriTrigger: 0,
    currentWidget: null,
    triggerTakeOff: 0,
    errorSize: false
  },
  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },
  actions: {
    SET_VALUE ({ commit }, keyValue) {
      commit('setValue', keyValue)
    }
  }
}
