export default {
  state: {
    AiPhotoStep: 0,
    selectedPhoto: null,
    isCreatedAiPhoto: false,
    aiPhotoSettingsReGenerate: null,
  },
  mutations: {
    setValue(state, keyValue) {
      state[keyValue.key] = keyValue.value;
    },
  },
  actions: {
    SET_VALUE({ commit }, keyValue) {
      commit("setValue", keyValue);
    },
  },
};
