<template>
  <div class="permission-root-wrapper">
    <v-menu
      v-if="isLimit || isAccessDenied || isBilling"
      open-on-hover
      style="position: relative; cursor: pointer; z-index: 9999"
      min-width="237"
      rounded="zarbo"
      :nudge-bottom="nudgeBottom ? nudgeBottom : 0"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="on"
          v-on="on"
          v-bind="attrs"
          :class="{
            'permission-team': isAccessDenied,
            'give-me-money': isLimit || isBilling,
          }"
          :style="iconStyles"
        ></div>
      </template>
      <div class="permissions__tooltip-wrapper" :style="textStyles">
        <p v-if="isLimit" class="permissions__title permissions-text">
          Достигнут лимит тарифа{{
            currentPlanLimit && currentPlanLimit.type_of === "counter"
              ? `(${currentPlanLimit.balance} из ${
                  currentPlanLimit.default_balance
                } до ${new Date(
                  currentPlanLimit.expires_at
                ).toLocaleDateString()})`
              : ""
          }}. Повысьте тариф чтобы создавать больше
        </p>
        <p
          v-else-if="isAccessDenied"
          class="permissions__title permissions-text"
        >
          У вас недостаточно прав
        </p>
        <p v-else-if="isBilling" class="permissions__title permissions-text">
          Не доступно на вашем тарифе.
        </p>
        <v-btn
          v-if="!isAccessDenied"
          class="permissions__btn-tarif mt-3"
          width="205"
          height="40"
          color="primary"
          depressed
          text
          href="https://zarbo.tech/prices"
          target="_blank"
          >Сменить тариф</v-btn
        >
      </div>
    </v-menu>
    <div
      :class="
        isLimit || isAccessDenied || isBilling ? 'grayscale no-click' : ''
      "
      :style="
        permissionCode === 'change_role_team'
          ? 'display: grid; grid-template-columns: repeat(2, 1fr); -moz-column-gap: 16px; column-gap: 16px; row-gap: 16px; justify-content: space-between;'
          : ''
      "
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    permissionCode: {
      // вобще доступ к сущности(чтение/обновление/удаление)
      type: String,
      required: false,
      default: "no-code",
    },
    billingValue: {
      // текушее значение квоты(к примеру сколько уже продуктов создано)
      type: Number,
      required: false,
    },
    billingCode: {
      // код квоты(есть ли у тебя в тарифе доступ к рендерам, к примеру). Если есть, то берется знчение отсюда
      type: String,
      required: false,
      default: "no-code",
    },
    textStyles: {
      type: Object,
      required: false,
    },
    iconStyles: {
      type: Object,
      required: false,
    },
    nudgeBottom: {
      type: Number,
      required: false,
    },
  },

  data: () => ({}),

  computed: {
    ...mapState({
      permissions: (state) => state.permissions.scopesAndPlans, // тут будут скопы с бека(из хранилища) в первозданном виде
    }),

    currentPlanLimit() {
      // конкретное ограничение тарифа или undefined
      if (!this.permissions) {
        return {};
      }
      return this.permissions.billing.find(
        (_quote) => _quote.code === this.billingCode
      );
    },

    currentScope() {
      if (this.permissionCode === "no-code") {
        return true;
      }
      return this.permissions?.team?.find(
        (_scope) => _scope.code === this.permissionCode
      );
    },

    currentBilling() {
      if (this.billingCode === "no-code") {
        return true;
      }
      return this.permissions?.billing?.find(
        (_billing) => _billing.code === this.billingCode
      );
    },

    isHaveScope() {
      return !!this.currentScope;
    },

    isHaveBilling() {
      return !!this.currentBilling;
    },

    isPlan() {
      // флаг что это лимит
      return !!this.currentPlanLimit;
    },

    isMoreThanPlanLimit() {
      // флаг что превысили лимит
      if (this.billingCode === "no-code") {
        // значение не передано
        return false;
      }
      if (this.currentPlanLimit === undefined) {
        return true;
      }

      switch (this.currentPlanLimit.type_of) {
        case "bool":
          return !this.currentPlanLimit.bool_value;

        case "numeric":
          return this.billingValue >= +this.currentPlanLimit.numeric_value;

        case "counter":
          // если передали billingValue то сравним его с default_balance
          // (использую в левом меню, чтобы не блокировать кнопку рендеров когда они закончатся)
          if (this.billingValue) {
            return this.currentPlanLimit.default_balance <= this.billingValue;
          } else {
            // стандартное вычисление counter-a
            return this.currentPlanLimit.balance <= 0; // настоящая проверка
            // return this.currentPlanLimit.balance <= 999 // тест
          }

        default:
          return true;
      }
    },

    status() {
      // итоговый статус
      if (!this.isHaveScope) {
        return "accessDenied";
      }

      if (!this.isHaveBilling) {
        return "billing";
      }

      if (this.isMoreThanPlanLimit) {
        // если привысили план
        return "limit";
      } else {
        return "ok";
      }
    },

    isLimit() {
      return this.status === "limit";
    },

    isAccessDenied() {
      return this.status === "accessDenied";
    },

    isBilling() {
      return this.status === "billing";
    },
  },
};
</script>

<style lang="scss">
.permission-root-wrapper {
  position: relative;
}
.give-me-money {
  position: absolute;
  right: 4px;
}
.give-me-money::before {
  content: "";
  position: absolute;
  z-index: 6;
  right: 4px;
  width: 18px;
  height: 18px;
  border-radius: 10px 10px 10px 2px;
  background: linear-gradient(
    267deg,
    #ff6935 -10.42%,
    #f05088 42.63%,
    #8531ef 100.03%
  );
}
.give-me-money::after {
  content: "";
  position: absolute;
  z-index: 7;
  top: 2px;
  right: 7px;
  width: 12px;
  height: 12px;
  background-image: url("@/assets/img/blocked.png");
}
.permission-team {
  position: absolute;
  right: 4px;
}
.permission-team::before {
  content: "";
  position: absolute;
  z-index: 6;
  right: 4px;
  width: 18px;
  height: 18px;
  border-radius: 10px 10px 10px 2px;
  background-color: #4e4087;
}
.permission-team::after {
  content: "";
  position: absolute;
  z-index: 7;
  top: 2px;
  right: 7px;
  width: 12px;
  height: 12px;
  background-image: url("@/assets/img/blocked.png");
}
.permissions {
  &__tooltip-wrapper {
    background-color: #4e4087;
    max-width: 237px;
    z-index: 999;
    border-radius: 10px;
    padding: 14px;
  }
  &-text {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    color: #fff !important;
  }
  &__change-tarif {
    min-height: 40px;
    padding: 0 24px !important;
    span {
      color: #ffffff;
    }
  }
  &__btn-tarif {
    border-radius: 10px;
    background-color: #dddcff;
  }
}
</style>