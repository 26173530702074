export default {
  state: {
    searchProducts: null,
    products: [],
    loadingProducts: false,
    totalProducts: 0,
    nextProducts: null,
    getProductsTrigger: 0,
    imagePreviewTrigger: 0,
    widgetPreviewTrigger: 0
  },
  mutations: {
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    }
  },
  actions: {
    SET_VALUE ({ commit }, keyValue) {
      commit('setValue', keyValue)
    }
  }
}
