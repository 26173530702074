<template>
  <div style="align-content: end;">
    <!-- v-model="showTooltip" -->
    <v-tooltip 
       v-if="tipByKey"
       :open-delay="$tips.openDelay" 
       v-bind="tooltipPosition" 
       :content-class="tooltipClasses"
       :nudge-top="tipByKey.nudgeTop"
       :nudge-right="tipByKey.nudgeRight"
       :nudge-bottom="tipByKey.nudgeBottom"
       :nudge-left="tipByKey.nudgeLeft"
       transition="false"
     >
       <template v-slot:activator="{ on, attrs }">
         <span v-bind="attrs" v-on="on">
           <slot></slot>
         </span>
       </template>
       <span>{{ tipByKey.text }}</span>
     </v-tooltip>
     <template v-else>
        <slot></slot>
      </template>
   </div>
</template>

<script>
export default {
  name: 'tooltips-component',

  data: () => ({
    showTooltip: true,
  }),

  props: {
    keyTips:  {
      type: String,
      default: ''
    },
    attachTo:  {
      type: String,
      default: ''
    },
  },

  computed: {
    tooltipPosition() {
      return { [this.tipByKey.position]: true }
    },

    tooltipClasses() { 
      let classes = ['zarbo-tips']; 
      
      classes.push(`tooltip-${this.tipByKey.position}`);

      return classes.join(' '); 
    },

    tipByKey() {
      return this.$tips.tips.find(tip => tip.key === this.keyTips) || null
    }
  }
}
</script>

<style lang="scss">
.zarbo-tips {
  border-bottom: 2px solid #9E92CF;
  background: #CFCDF6 !important;
  // padding: 8px 12px !important;
  opacity: 1 !important;
  height: 32px;
  span {
    color: #4E4087;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
// Стрелки
.tooltip-bottom::before{
  content: '';
  position: absolute;
  left: 50%;
  bottom: 96%;
  transform: translateX(-50%);
  width: 24px;
  height: 12px;
  background: url('../../assets/img/arrow-tips.svg') no-repeat center center;
  background-size: contain;
}

.tooltip-left::before{
  content: '';
  position: absolute;
  top: 50%;
  right: -17px;
  transform: translateY(-50%) rotate(90deg);
  width: 24px;
  height: 12px;
  background: url('../../assets/img/arrow-tips.svg') no-repeat center center;
  background-size: contain;
}
</style>